import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./vrpListPage.module.css";

import { VrpItem } from "../../components/vrpItem/VrpItem";

import { FilterPage } from "./filter/FilterPage";
import useGetVrpSortedList from "../../tanstack-query/vrp/useGetVrpSortedList";
import axiosInstance from "../../utils/axios-middleware/axiosMiddleware";
import { useQuery } from "@tanstack/react-query";
import { Carousel } from "../../components/carousel/Carousel";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import useAddToWishListMutation from "../../tanstack-query/wishList/useAddToWishListMutation";
import { BannerSkeleton } from "../../components/skeletons/bannerSkeleton/BannerSkeleton";
import { ProductSkeleton } from "../../components/skeletons/productSkeleton/ProductSkeleton";
import { BestSellingCardMessage } from "../../components/skeletons/bestSellingCardMessage/BestSellingCardMessage";

import { Banner } from "../../components/banner/Banner";
import useGetAdvertisement from "../../tanstack-query/advertisement/useGetAdvertisement";

export const VrpListPage = () => {
  const [vrpListData, setVrpListData] = useState([]);
  const [inFilterMode, setInFilterMode] = useState(false); // Define open state

  const [filters, setFilters] = useState({
    sort: null,
    apple_per_start: null,
    apple_per_end: null,
    asp_start: null,
    asp_end: null,
    p4_percent_start: null,
    p4_percent_end: null,
  });
  const advertisementFilters = { category: "vrp", page: "listing" };
  const authToken = Cookies.get("authToken");
  const userId = Cookies.get("user_id");
  const guestId = Cookies.get("guestId");
  const medium = authToken ? "user" : "guest";
  const user_id = authToken ? userId : guestId;
  const { data: add, isSuccess: addIsSuccess } =
    useGetAdvertisement(advertisementFilters);

  const { data, isLoading, isSuccess, refetch } = useGetVrpSortedList(
    filters,
    user_id,
    medium
  );

  const {
    mutateAsync,
    isLoading: isAdding,
    isSuccess: isAdded,
    isPending,
  } = useAddToWishListMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setVrpListData(data.data.data);
    }
  }, [data, isSuccess]);

  const location = useLocation();

  useEffect(() => {
    if (!inFilterMode) {
      const extractFiltersFromURL = () => {
        const searchParams = new URLSearchParams(location.search);
        const extractedFilters = {
          sort: searchParams.get("sort"),
          apple_per_start: searchParams.get("apple_per_start"),
          apple_per_end: searchParams.get("apple_per_end"),
          asp_start: searchParams.get("asp_start"),
          asp_end: searchParams.get("asp_end"),
          p4_percent_start: searchParams.get("p4_percent_start"),
          p4_percent_end: searchParams.get("p4_percent_end"),
        };
        setFilters(extractedFilters);
      };
      extractFiltersFromURL();
    }
  }, [inFilterMode, location.search]);

  const handleApplied = async (filters) => {
    try {
      setFilters({ ...filters });

      await refetch();
      setVrpListData(data.data.data);
    } catch (err) {
      console.error("Error applying filters:", err);
    }
  };
  const handleAddToWishList = async (event, item) => {
    event.stopPropagation();

    const data = {
      category_id: item.category_id,
      request_id: item.request_id,
    };

    try {
      const response = await mutateAsync(data);
      toast.success(response.message.displayMessage);
      console.log(item);
    } catch (error) {
      // toast.error(error.response.message.displayMessage);
    }
  };

  const navigateToVrpDetail = (requestId) => {
    navigate(`${requestId}`);
  };

  return (
    <div className={classes.box}>
      {isSuccess && vrpListData.length > 0 && (
        <FilterPage
          onFilterSort={handleApplied}
          setFilterMode={setInFilterMode}
          filters={filters}
        />
      )}

      {addIsSuccess ? <Banner data={add?.data} /> : <BannerSkeleton />}
      <div className={classes.box__item}>
        {isLoading ? (
          <ProductSkeleton /> // Render skeleton while loading
        ) : isSuccess ? (
          vrpListData?.length > 0 ? (
            <div className={classes.box__item}>
              {vrpListData.map((vrpItem, index) => (
                <VrpItem
                  key={vrpItem.request_id}
                  item={vrpItem}
                  index={index}
                  totalItems={vrpListData.length}
                  onClick={navigateToVrpDetail}
                  onWishList={(event) => handleAddToWishList(event, vrpItem)}
                />
              ))}
            </div>
          ) : (
            <BestSellingCardMessage />
          )
        ) : null}
      </div>
    </div>
  );
};
